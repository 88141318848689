p, h1, h2, h3 {
    color: #fff;
}

.contact-flex-container {
    background-image: radial-gradient(68.66% 68.42% at 23.35% 21.26%, #002629 0%, #030014 100%);
}

main {
    background-image: radial-gradient(68.66% 68.42% at 23.35% 21.26%, #002629 0%, #030014 100%);
}

/*header {*/
   /* background-color: rgba(25, 37, 44);*/ /* Setează transparența inițială */
    /*margin: 0;
    color: #fff;
    padding: 0 20px; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    transition: background-color 0.3s ease-in-out;
}*/

.footer-pin {
    position: relative;
    left: 0;
    bottom: 0;
  }


.contact1 {
    color: rgb(255, 255, 255);
    justify-content: center; /* Centrarea conținutului pe orizontală */
    text-align: center;
    width: 100%; /* Asigură că div-ul ocupă întreaga lățime */
    }

.contact12 {
    display: flex;
    justify-content: center;
    align-items: center; /* Asigură că elementele sunt aliniate pe centru vertical */
    text-align: center;
}

.contact13 {
    display: flex;
    justify-content: center;
    align-items: center; /* Asigură că elementele sunt aliniate pe centru vertical */
    text-align: center;
}
 
.contact2 {
    text-align: center;
}    

.contact3 {
    text-align: center;
}

.contact-flex-container {
    display: flex;
    justify-content: space-between; /* Distribuie spațiul uniform între elemente */
    align-items: center; /* Aliniază elementele la partea de sus */
    text-align: left; /* Textul aliniat la stânga */
    padding: 20px 0; /* Spațiu vertical pentru respirație */
    min-height: calc(100vh - 34px);
}

.contact-left {
    font-size: 25px;
}



.contact-left, .contact-middle, .container {
    flex: 1; /* Fiecare element să ocupe o treime din spațiu */
    margin: 0 10px; /* Puțin spațiu între coloane */
}

.contact-middle {
    margin-left: 18%;
}

.contact-container {
    max-width: 400px; /* Micsorează această valoare pentru a reduce lățimea formularului */
    margin: 40px auto; /* Asigură că formularul este centrat pe pagina */
    padding: 20px;
    background-color: inherit;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: left;
}

.container.contact-container {
    margin-top: 0px; /* Ajustează această valoare conform nevoilor tale */
    max-width: 400px;
    margin: 40px auto;
    padding: 20px;
    background-color: inherit;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: left;
    font-size: 25px;
}


.contact-container h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.contact-form .form-group {
    margin-bottom: 20px;
}

.contact-form .form-group input,
.contact-form .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 2px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Așa că paddingul nu adaugă la lățimea totală */
    transition: border-color 0.3s;
    background-color: inherit;
    color: #fff;
}

.contact-form .form-group input:focus,
.contact-form .form-group textarea:focus {
    border-color: #007bff;
    outline: none;
}

.submit-btn {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #00033f;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.submit-btn:hover {
    background-color: rgb(222, 188, 0)
}

img.svg-icon {
    width: 80px;
    height: 80px;
    margin-right: 8px;
    align-items: center;
    text-align: center;
    padding: 10px;
}




/* Stiluri pentru tablete */
@media (max-width: 768px) {
    .contact-flex-container {
        flex-direction: column;
        text-align: center;
        margin-top: 20px;
    }

    .contact-left, .contact-middle, .contact-container {
        flex: none;
        max-width: 100%;
        margin: 10px 0;
    }

    .contact12, .contact13 {
        flex-direction: column;
    }

    .contact-container {
        margin-top: 0;
        max-width: 100%;
        padding: 20px;
    }

    footer {
        bottom: 0;
    }

    .img.svg-icon {
        width: 60px; /* Ajustat pentru tablete */
        height: 60px; /* Ajustat pentru tablete */
    }
}

    .submit-btn {
        /* Adjust button padding if needed */
        padding: 12px;
    }

    /* You can also adjust font sizes for smaller screens */
    .contact-container h2,
    .contact-container p,
    .contact-form label {
        font-size: 18px; /* Or whatever size you prefer */
    }

    /* Adjusting the size of the input fields and button for easier touch */
    .contact-form input[type="text"],
    .contact-form input[type="email"],
    .contact-form textarea,
    .submit-btn {
        font-size: 16px; /* Larger font size for better readability */
    }

    @media (max-width: 480px) {
        .contact-form .form-group input, .contact-form .form-group textarea {
            padding: 8px;
        }
    
        .img.svg-icon {
            width: 40px; /* Ajustat pentru telefoane */
            height: 40px; /* Ajustat pentru telefoane */
        }
    
        .submit-btn {
            padding: 12px;
        }
    
        .contact-container h2, .contact-container p, .contact-form label {
            font-size: 18px;
        }

    }

