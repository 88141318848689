/* Stiluri pentru sectiunea de preturi cu efect parallax */


.parallax-tarife, .parallax-tarife2 {
    margin: 0;
    padding: 0;
    background-attachment: fixed;
    min-height: 100vh
}


.parallax-tarife::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Ajustează opacitatea și culoarea la nevoie */
    z-index: -1; /* Asigură-te că este sub text */
}


.parallax-tarife2 {
    background-attachment: scroll; /* Schimbat de la 'relative' la 'scroll' */
    background-position: center center; /* Schimbat de la 'auto' la 'center center' */
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../public/images/detailing3.jpg');
    /* Asigură-te că secțiunea are suficientă înălțime pentru a umple ecranul */
    min-height: 100vh; /* Ajustează această valoare dacă este necesar */
    padding: 0 1.5rem;
}

/* Alte stiluri pentru sectiunea de preturi... */


/* Stilurile pentru centrarea textului pe mijloc */
.pricing {
    text-align: center;
    padding: 20px; /* Adaugă o margine în jurul textului pentru a îmbunătăți vizualizarea */
}

/* Stilurile pentru textul informativ */
.pricing h2 {
    margin-bottom: 20px;
}

/* Stilurile pentru lista de tarife */
.pricing-list {
    list-style: none;
    padding: 0;
}

/* Stilurile pentru fiecare element de listă */
.pricing-item {
    margin-bottom: 10px;
}

/* Stilurile pentru preturi */
.pricing-price {
    font-weight: bold;
    color: #e8491d; /* Culoarea prețului pentru a atrage atenția */
}

/* Stilurile pentru detalii */
.pricing-details {
    margin-top: 10px;
    color: #652121; /* Culoare mai închisă pentru detaliile secundare */
}

.container5{
    background-image: url('../public/images/detailing2.jpg');
    background-attachment: scroll; /* Schimbat de la 'relative' la 'scroll' */
    background-position: center center; /* Schimbat de la 'center bottom' la 'center center' */
    background-repeat: no-repeat;
    background-size: 200% auto;
    color: #ffffff;
    /* Asigură-te că secțiunea are suficientă înălțime pentru a umple ecranul */
    min-height: 100vh; /* Ajustează această valoare dacă este necesar */
    padding: 10px 2.5rem;
} 

.container2 {
    max-width: 500px;
    padding: 2rem 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
}
/* ... Alte stiluri pentru diverse tipuri de prețuri sau detalii ... */

@media (max-width: 767px) {
    .container5 {
        background-attachment: scroll; /* Sau folosește 'fixed' dacă este mai potrivit pentru mobil */
        background-size: 650% auto; /* Ajustează imaginea pentru a acoperi complet containerul */
        min-height: 100vh; /* Poți ajusta aceasta dacă este necesar */
        /* Poate doriți să dezactivați parallax-ul din JavaScript pe mobil */
    }
}
