/* Setări de bază */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

body {
  font-family: 'Montserrat', sans-serif;
}

/* Stiluri pentru secțiunea .hero1 */
.hero1 {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: none;
  margin: 0;
  padding: 0;
  z-index: 0;
}



.backgroundVideo {
  position: absolute !important;
  top: 50% !important;
  left: 50% !important;
  width: 100vw !important;
  height: 160vh !important;
  object-fit: cover !important;
  transform: translate(-50%, -50%) !important;
  z-index: -1 !important;
  pointer-events: none; /* Previne interacțiunile utilizatorului cu videoclipul */
}

.section-retapitare {
  display:flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.container12 {
  position: relative !important;
  z-index: 2 !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100% !important;
  width: 100% !important;
  color: #fff !important;
  text-align: center !important;
  padding: 0 !important;
  margin: 0 !important;
}

.hero1 h1 {
  font-size: 3em !important;
  margin-bottom: 20px !important;
  color: #fff !important;
}

.hero1 p {
  font-size: 1.2em !important;
  margin-bottom: 40px !important;
  color: #ffffff !important;
}

.ambient-lights-item button {
  background-color: #0023d4 !important;
  color: #ffffff !important;
  border: none !important;
  padding: 10px 20px !important;
  text-transform: uppercase !important;
  cursor: pointer !important;
  transition: all 0.5s ease !important;
  border-radius: 20px !important;
  margin-bottom: 10px !important;
}

/* Media queries pentru dispozitive mobile */
@media (max-width: 768px) {
  .backgroundVideo {
    width: 160vh !important;
    height: 100vh !important;
    object-fit: cover !important;
  }

  .section-retapitare {
    display: block;
    margin: 0 auto;
    padding: 20px;
  }

  .hero1 h1 {
    font-size: 2.5em;
  }

  .hero1 p {
    font-size: 1em;
  }

  .container12 {
    padding: 0 !important;
    margin: 0 !important;
  }
}

@media (max-width: 480px) {
  .backgroundVideo {
    width: 178vh !important;
    height: 100vh !important;
    object-fit: cover !important;
  }

  .hero1 h1 {
    font-size: 2em;
  }

  .hero1 p {
    font-size: 0.9em;
  }

  .cta-button-test {
    padding: 10px 20px;
    font-size: 1em;
  }

  .container12 {
    padding: 0 !important;
    margin: 0 !important;
  }
}
