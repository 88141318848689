

html, body {
    font-family: 'Montserrat' cursive;
    margin: 0;
    padding: 0;
}

body {
    background-image: radial-gradient(68.66% 68.42% at 23.35% 21.26%, #12023B 0%, #030014 100%);
}

main {
    background-image: radial-gradient(68.66% 68.42% at 23.35% 21.26%, #002629 0%, #030014 100%);
}

.info {
    margin: 20px;
}

.content {
    background-image: radial-gradient(68.66% 68.42% at 23.35% 21.26%, #12023B 0%, #030014 100%);
}

h1, h2, h3 {
    font-family: 'Montserrat' cursive;
}

p1 {
    font-family: 'Montserrat' cursive;
}

.section-reparatii-trapa {
    display: flex;
    justify-content: center;
    align-items:center;
    overflow: hidden;
}

.retapitare-item button {
    background-color: #0023d4; /* Schimbă culoarea de fundal a butonului */
    color: #ffffff; /* Schimbă culoarea textului */
    border: none; /* Elimină bordura butonului */
    padding: 10px 20px; /* Adaugă un padding pentru a mări butonul */
    text-transform: uppercase; /* Opțional: transformă textul în majuscule */
    cursor: pointer; /* Schimbă cursorul când este deasupra butonului */
    transition: all 0.5s ease; /* Animă toate schimbarile, inclusiv schimbarea culorii de fundal */
    border-radius: 20px;
    margin-bottom: 10px;
  }
  
  /* Stilul pentru buton atunci când este hover */
  .retapitare-item button:hover {
    background-color: #0056b3; /* Schimbă culoarea de fundal a butonului când este hover */
    /* Poți adăuga aici și alte stiluri pentru hover */
    transform: scale(1.05);
  }

  .reparatii-trapa-item button {
    background-color: #0023d4; /* Schimbă culoarea de fundal a butonului */
    color: #ffffff; /* Schimbă culoarea textului */
    border: none; /* Elimină bordura butonului */
    padding: 10px 20px; /* Adaugă un padding pentru a mări butonul */
    text-transform: uppercase; /* Opțional: transformă textul în majuscule */
    cursor: pointer; /* Schimbă cursorul când este deasupra butonului */
    transition: all 0.5s ease; /* Animă toate schimbarile, inclusiv schimbarea culorii de fundal */
    border-radius: 20px;
    margin-bottom: 10px;
  }
  
  /* Stilul pentru buton atunci când este hover */
  .reparatii-trapa-item button:hover {
    background-color: #0056b3; /* Schimbă culoarea de fundal a butonului când este hover */
    /* Poți adăuga aici și alte stiluri pentru hover */
    transform: scale(1.05);
  }

/* Stilizează containerul Swiper */
.swiper-container {
    position: relative;
    width: 60%; /* Ajustează lățimea după necesități */
    height: auto;
    margin: 20px auto; /* Acesta va centra containerul pe orizontală */
}

/* Stilizează imaginile din interiorul Swiper */
.swiper-slide img {
    height: 100%;
    max-height: 90vh; 
    width: 100%;
    object-fit: cover;
    margin: auto;
    border-radius: 85px;
    overflow: hidden;
}

/* Stilizează butoanele de navigație Swiper */
.swiper-button-prev, .swiper-button-next {
    position: absolute;
    top: 50%; 
    transform: translateY(-50%);
    z-index: 10;
    width: 50px;
    height: 50px;
    background-size: contain;
}

.swiper-button-prev {
    left: calc(-100% - 50px); /* Muta butonul în afara containerului */
}

.swiper-button-next {
    right: calc(-100% - 50px); /* Muta butonul în afara containerului */
}


  

/* Ajustări pentru responsive design */
@media (max-width: 768px) {
    .swiper-container {
        width: 90%; /* Lățimea containerului ajustată pentru tablete */
    }

    .swiper-slide img {
        max-height: 80vh; /* Înălțimea imaginilor ajustată pentru tablete */
        max-width: 80vh;
    }

    .swiper-button-prev, .swiper-button-next {
        width: 40px; /* Diminuăm dimensiunea butoanelor */
        height: 40px; /* Diminuăm dimensiunea butoanelor */
        background-size: 20px; /* Ajustăm mărimea imaginilor butoanelor */
    }

    .swiper-button-prev {
        left: calc(-100% - 20px); /* Ajustăm poziția pentru ecrane mai mici */
    }

    .swiper-button-next {
        right: calc(-100% - 20px); /* Ajustăm poziția pentru ecrane mai mici */
    }

    .section-reparatii-trapa {
        display: flex;
        justify-content: center;
        align-items:center;
        max-width: fit-content;
        max-height: fit-content;
    }

    /* #checkbox {
        display: none;
        z-index: 2;
      }
      
      .toggle {
        position: relative;
        width: 40px;
        height: 40px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        transition-duration: .3s;
        z-index: 1000;
      }
      
      .bars {
        width: 100%;
        height: 4px;
        background-color: rgb(253, 255, 243);
        border-radius: 5px;
        transition-duration: .3s;
      } */
      
      /* #checkbox:checked + .toggle .bars {
        margin-left: 13px;
      } */
      
      /* #checkbox:checked + .toggle #bar2 {
        transform: translateY(14px) rotate(60deg);
        margin-left: 0;
        transform-origin: right;
        transition-duration: .3s;
        z-index: 5;
      }
      
      #checkbox:checked + .toggle #bar1 {
        transform: translateY(28px) rotate(-60deg);
        transition-duration: .3s;
        transform-origin: left;
        z-index: 1;
      }
      
      #checkbox:checked + .toggle {
        transform: rotate(-90deg);
      } */
      /* #checkbox:checked + .toggle #bar3 {
        transform: rotate(90deg);
        transition-duration: .3s;
        transform-origin:right;
      } */
}

@media (max-width: 480px) {
    

    .swiper-slide img {
        max-height: 80vh;
    }

    .swiper-button-prev {
        left: calc(-100% - 15px); /* Ajustează pentru dispozitive mobile dacă este necesar */
    }

    .swiper-button-next {
        right: calc(-100% - 15px); /* Ajustează pentru dispozitive mobile dacă este necesar */
    }
}

/* Stilizează paginarea Swiper */
.swiper-pagination-bullet {
    transform: scale(0.75);
}
