*{
  padding:0;
  margin:0;
  box-sizing: border-box;
}
html {
  background-color: #000000;
  font-family: 'Montserrat' , cursive;
  max-width: 100%;
  
}
/* Resetare stiluri implicite ale browserului */
body, h1, h2, p, ul, img, a {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.content {
  background-image: radial-gradient(68.66% 68.42% at 23.35% 21.26%, #12023B 0%, #030014 100%);
}

body {
  font-family: 'Montserrat';
  line-height: 1.6;
  max-width: 100%;
  overflow-x: hidden;
}

/* Always set the map height explicitly to define the size of the div
 element that contains the map. */
gmp-map {
  height: 100%;
}

/* Optional: Makes the sample page fill the window. */
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  min-height: calc(100vh - 34px);
}

.container {
  max-width: 100%;
  margin: 0 auto;
  padding: 0 ;
  color: #fff;
}

.container11 {
  position: absolute;
  top: 50%; /* Ajustează procentul sau folosește pixeli pentru a poziționa exact unde dorești */
  left: 50%;
  transform: translate(-50%, -50%); /* Centrarea containerului */
}

/* header { */
/*   background-color: rgba(25, 37, 44, 0.8); /* Setează transparența inițială */ 
/*   margin: 0; */
/*   color: #fff; */
/*   padding: 0 20px; */
/*   position: fixed; */
/*   top: 0; */
/*   left: 0; */
/*   width: 100%; */
/*   z-index: 10; /* Ajustat pentru a fi deasupra altor elemente */ 
/*   transition: background-color 0.3s ease-in-out; */
/*   display: flex; */
/*   justify-content: space-between; */
/*   align-items: center; */
/* } */

/* header.scrolled { */
/*   background-color: rgba(25, 37, 44, 1); /* Devine opac la scroll */ 
/* } */

/* header .container { */
/*   padding: 0; */
/*   display: flex; */
/*   flex-direction: row; */
/*   justify-content: flex-end; */
/* } */

/* header.scrolled, nav ul.scrolled { */
/*   background-color: rgba(25, 37, 44, 1); /* Devine opac la scroll */ 
/* } */

/* facebook-logo { */
/*   display: flex; */
/*   align-items: auto; /* Acesta va asigura că logo-ul este centrat vertical */ 
/* } */

/* .facebook-logo img { */
/*   max-width: 30px; /* Ajustează după preferințe */ 
/*   height: 30px; */
/* } */

/* a { */
/*   text-decoration: none; */
/*   color: inherit; */
/* } */

/* nav { */
/*   width: 100%; */
/*   display: flex; */
/*   flex-direction: row; */
/*   align-items: center; */
/*   justify-content: flex-end; */
/* } */

/* nav ul { */
/*   background-color: rgba(25, 37, 44); /* Setează transparența inițială */ 
/*   list-style: none; */
/*   margin: 0; */
/*   padding: 0; */
/*   position: absolute; */
/*   top: -100%; /* Schimbă poziția inițială pentru a ascunde meniul */ 
/*   left: 0; */
/*   width: 100%; */
/*   transition: top 0.5s ease-in-out; /* Tranziția pentru efectul dinamic */ 
/* } */

/* nav ul li { */
/*   display: inline-block; */
/*   margin-right: 20px; */
/* } */

/* nav ul li a { */
/*   padding: 5px 20px; */
/*   text-decoration: none; */
/*   color: #fff; */
/*   font-size: 1.1em; */
/*   transition: color 0.3s ease; */
/* } */

/* nav ul li a:hover { */
/*   color: #ececec; */
/* } */


main {
  background-color: #000000;
}

/* Stilul de bază pentru buton */
.pricing-card-price button {
  background-color: #0023d4; /* Schimbă culoarea de fundal a butonului */
  color: #ffffff; /* Schimbă culoarea textului */
  border: none; /* Elimină bordura butonului */
  padding: 10px 20px; /* Adaugă un padding pentru a mări butonul */
  text-transform: uppercase; /* Opțional: transformă textul în majuscule */
  cursor: pointer; /* Schimbă cursorul când este deasupra butonului */
  transition: all 0.5s ease; /* Animă toate schimbarile, inclusiv schimbarea culorii de fundal */
  border-radius: 20px;
  margin-bottom: 10px;
}

/* Stilul pentru buton atunci când este hover */
.pricing-card-price button:hover {
  background-color: #0056b3; /* Schimbă culoarea de fundal a butonului când este hover */
  /* Poți adăuga aici și alte stiluri pentru hover */
  transform: scale(1.05);
}

.pricing-table button {
  background-color: #0023d4; /* Schimbă culoarea de fundal a butonului */
  color: #ffffff; /* Schimbă culoarea textului */
  border: none; /* Elimină bordura butonului */
  padding: 10px 20px; /* Adaugă un padding pentru a mări butonul */
  text-transform: uppercase; /* Opțional: transformă textul în majuscule */
  cursor: pointer; /* Schimbă cursorul când este deasupra butonului */
  transition: all 0.5s ease; /* Animă toate schimbarile, inclusiv schimbarea culorii de fundal */
  border-radius: 20px;
  margin-top: 15px;
}

/* Stilul pentru buton atunci când este hover */
.pricing-table button:hover {
  background-color: #0056b3; /* Schimbă culoarea de fundal a butonului când este hover */
  /* Poți adăuga aici și alte stiluri pentru hover */
  transform: scale(1.05);
}

.hero {
  padding: 100px 0;
  text-align: center;
  background: url('../public/images/deniz-demirci-0UeHGWsFyHM-unsplash.jpg') center center/cover;
  color: #fff;
  position: relative;
  overflow: hidden;
  height: 100vh;
  animation: slideBackground 15s infinite linear;
}

.hero::before {
  content: '';
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.hero h1 {
  font-size: 3em;
  margin-bottom: 20px;
  color: #fff;
}

.hero p {
  font-size: 1.2em;
  margin-bottom: 40px;
  color: #ffffff;
}

.cta-button-test,
nav ul li a {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 180px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.cta-button-test {
  background-color: #e8491d;
  color: #fff;
  text-decoration: none;
  font-size: 1.2em;
}

.cta-button-test:hover,
nav ul li a:hover {
  background-color: #ececec;
  color: #19252c;
}

.features {
  padding: 80px 0;
}



@keyframes slideBackground {
  0% {
      background-position: 0% 50%;
  }
  100% {
      background-position: 100% 50%;
  }
}

.parallax-hero {
  background-attachment: fixed;
}

footer {
  background-color: #19252c;
  color: #fff;
  text-align: center;
  padding: 1em 0;
  
}

.hamburger {
  display: none;
  font-size: 2.5rem;/* 1rem = 16px*/
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  z-index:10000
}

/* Media queries pentru tablete */
@media (max-width: 768px) {
  .container, header, .hero h1, .hero p, .features, footer {
      padding: 0 15px;
  }
  .container{
      position: relative;
  }

  /*header.scrolled, nav ul.scrolled {
    background-color: rgba(25, 37, 44, 1); /* Devine opac la scroll */
    /*opacity: 0.5; /* Exemplu: Setează opacitatea la 0.5 *//*
  }*/

  /*nav{
      height: 50px;
  }
  nav ul{
      width:100%;
      position:absolute;
      top:-240px;
      left:0;
      transition: all .8s ease-in-out;
      transition: transform 0.5s ease-in-out; /* Adaugă tranzitia */
    /*transform: translateY(-100%); /* Inițial, meniul este ascuns sus *//*
  }*/

  .hero {
      padding: 50px 0;
  }

  .hero h1 {
      font-size: 2em;
  }

  .hero p {
      font-size: 1em;
  }

  /*nav ul {
     
      flex-direction: column;
      width: 100%;
      transition: transform 0.5s ease-in-out; /* Adaugă tranzitia */
      /*transform: translateY(-100%); /* Inițial, meniul este ascuns sus *//*
  }

  /* nav ul li {
      text-align: center;
      width: 100%;
  }

  nav ul li a {
      display: block;
      width: 100%;
      padding: 10px;
  } */

  .hamburger {
      display: block;
      
  }

  nav ul.active {
      top:48px;
      z-index: 1000;
      border-bottom-right-radius:1rem ;
      border-bottom-left-radius:1rem ;
      max-height: 500px; /* ajustează conform necesităților tale */
      transform: translateY(0%);
      transition: transform 0.5s ease-in-out; /* Adaugă tranzitia */
  }
}

/* Media queries pentru telefoane */
@media (max-width: 480px) {
  .container, header, .hero h1, .hero p, .features, footer {
      padding: 0 10px;
  }

  .hero {
      padding: 20px 0;
  }

  .hero h1 {
      font-size: 1.8em;
  }

  .hero p {
      font-size: 0.9em;
  }

  nav ul li a {
      padding: 5px;
      font-size: 0.8em;
  }

  .cta-button-test, nav ul li a {
      padding: 10px 20px;
  }

  .facebook-logo img {
      max-width: 25px; /* Reducem dimensiunea logo-ului */
      height: 25px;
  }

  footer {
      padding: 20px 10px; /* Sporim padding-ul pentru a crește lizibilitatea */
  }

  .features {
      padding: 60px 0; /* Reducem padding-ul pentru a economisi spațiu */
  }
}