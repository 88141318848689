/* Stiluri generale pentru header */
header {
    background-color: rgba(25, 37, 44, 0.8); /* Setează transparența inițială */
    margin: 0;
    color: #fff;
    padding: 0 20px; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10; /* Ajustat pentru a fi deasupra altor elemente */
    transition: background-color 0.3s ease-in-out;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

header.scrolled {
    background-color: rgba(25, 37, 44, 1); /* Devine opac la scroll */
}

header .container {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

/* Stiluri pentru navigare */
nav {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

nav ul {
    background-color: rgba(25, 37, 44); /* Setează transparența inițială */
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    max-height: 0; /* Inițial ascuns */
    overflow: hidden; /* Ascunde conținutul care depășește */
    transition: max-height 0.5s ease-in-out; /* Tranziția pentru efectul dinamic */
}

nav ul.active {
    max-height: 300px; /* Ajustează această valoare în funcție de înălțimea meniului */
    background-color: rgba(25, 37, 44, 0.9);
}

nav ul.scrolled {
    background-color: rgba(25, 37, 44, 1); /* Devine opac la scroll */
}

nav ul li {
    margin: 0;
    text-align: center;
    margin-right: 20px;
}

nav ul li a {
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    font-size: 1.1em;
    display: block;
    transition: color 0.3s ease, background-color 0.3s ease;
}

nav ul li a:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ececec;
}

.facebook-logo {
    display: flex;
    align-items: auto; /* Acesta va asigura că logo-ul este centrat vertical */
    justify-content: center;
}

.facebook-logo img {
    max-width: 30px; /* Ajustează după preferințe */
    height: 30px;
}

a {
    text-decoration: none;
    color: inherit;
}

/* Stiluri pentru butonul hamburger */
/*.hamburger {
    display: none;
    font-size: 2.5rem; /* 1rem = 16px */
   /* background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    z-index: 10000;
}*/

/* Stiluri pentru toggle */
#checkbox {
    display: none;
    z-index: 2;
}

.toggle {
    position: relative;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition-duration: 0.3s;
    z-index: 1000;
}

.bars {
    width: 100%;
    height: 4px;
    background-color: rgb(253, 255, 243);
    border-radius: 5px;
    transition-duration: 0.3s;
}

.toggle .bars {
    transition: transform 0.3s ease;
}

#checkbox:checked + .toggle #bar2 {
    transform: translateY(14px) rotate(60deg);
    transform-origin: right;
    transition-duration: 0.3s;
    z-index: 5;
}

#checkbox:checked + .toggle #bar1 {
    transform: translateY(28px) rotate(-60deg);
    transition-duration: 0.3s;
    transform-origin: left;
    z-index: 1;
}

#checkbox:checked + .toggle {
    transform: rotate(-90deg);
}

/* Responsive Styles */
@media (max-width: 768px) {
    nav ul {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top:0px;
        left: 0;
        transition: max-height 0.5s ease-in-out; /* Tranziția pentru efectul dinamic */
        background-color: rgba(25, 37, 44, 0.9); /* Asigură că are același fundal ca header-ul */
    }
    nav ul.active {
        max-height: 300px; /* Ajustează această valoare în funcție de înălțimea meniului */
        transition: max-height 0.5s ease-in-out;
        top:0px;
        width: 100%;
    }

    nav ul.active.scrolled {
        background-color: rgba(25, 37, 44, 1); /* Devine opac la scroll */
    }

    nav ul li {
        margin: 0;
        text-align: center;
        width: 100%;
    }
    .toggle {
        display: flex;
    }
}

@media (min-width: 769px) {
    .toggle {
        display: none;
    }
    nav ul {
        display: flex;
        position: static;
        background-color: transparent;
        max-height: none; /* Elimina restricția de înălțime pe ecrane mari */
        overflow: visible; /* Permite afișarea completă a meniului */
        top: auto;
        left: auto;
        width: auto;
    }
    nav {
        justify-content: flex-start;
    }
}