html {
    background-image: radial-gradient(68.66% 68.42% at 23.35% 21.26%, #002629 0%, #030014 100%);
    font-family: 'Montserrat' , cursive;
    margin: 0;
    padding: 0;
}

h2 {
    color: #ececec;
}

/*header {
    background-color: rgba(25, 37, 44); /* Setează transparența inițială */
   /* margin: 0;
    color: #fff;
    padding: 0 20px; 
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    transition: background-color 0.3s ease-in-out;
}*/

/* .hamburger {
    display: none;
    font-size: 30px;
    background: none;
    border: none;
    color: #fff;
    cursor: pointer;
    align-items: center;
    justify-content: center;
} */

footer {
    background-color: #19252c;
    color: #fff;
    text-align: center;
    padding: 1em 0;
}

/*header.scrolled {
    background-color: rgba(25, 37, 44, 1); /* Devine opac la scroll */
/*}*/
/* facebook-logo {
    display: flex;
    align-items: auto; /* Acesta va asigura că logo-ul este centrat vertical *//*
} */

.facebook-logo img {
    max-width: 30px; /* Ajustează după preferințe */
    height: 30px;
}

h1, p {
    color: #ececec;
}

a{
    text-decoration: none;
    color: inherit;
}


/* nav ul {
    list-style: none;
}

nav ul li {
    display: inline-block;
    margin-right: 20px;
}

nav ul li a {
    padding: 5px 20px;
    text-decoration: none;
    color: #fff;
    font-size: 1.1em;
    transition: color 0.3s ease;
}

nav ul li a:hover {
    color: #000000;
} */

body {
    font-family: 'Montserrat';
    line-height: 1.6;
}

.container-politica {
    max-width: 800px; /* sau lățimea pe care o preferi */
    margin: auto;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    margin-top: 30px; /* sau cât dorești pentru a avea un spațiu mai mare sub navbar */
}

.privacy-policy{
    margin-top: 20px;
}

/* Stilizare pentru titlul secțiunii */
.container-politica h1 {
    text-align: center;
    margin-bottom: 20px;
}

/* Stilizare pentru paragraful cu textul politicii */
.container-politica p {
    text-align: justify; /* sau 'left' dacă preferi aliniament la stânga */
    line-height: 1.6; /* Ajustează înălțimea liniei după preferințe */
}

@media (max-width: 768px) {
    /* .hamburger {
        display: block;
    } */

    header, footer {
        padding: 0 15px;
    }

    /* nav ul {
        display: none;*/ /* inițial meniul este ascuns */
        /*flex-direction: column;
        justify-content: center;*/ /* centrat vertical */
        /*align-items: center;*/ /* centrat orizontal */
        /*width: 100%;
        top: 40px;*/ /* distanța de top, ajustează conform înălțimii nav bar-ului */
        /*background-color: rgba(25, 37, 44, 0.95);*/ /* fundal pentru meniu */
        /*margin-top: -50px;
        padding-top: 20px; *//* spațiere pentru partea de sus a meniului */
        /*padding-bottom: 10px;*/ /* spațiere pentru partea de jos a meniului */
    /*} */

    /* nav ul li {
        text-align: center;
        width: 100%;
    }

    nav ul li a {
        display: block;
        width: 100%;
        padding: 10px;
    }

    nav ul.active {
        display: flex;
    } */

}    